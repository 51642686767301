import { autoInjectable } from 'tsyringe';
import VrObject3D from './Three/VrObject3D';
import {
    Object3D,
    Box3,
    Vector3,
    BoxGeometry,
    MeshBasicMaterial,
    Mesh,
    Quaternion,
} from 'three';
import IntersectionContainer from './Controllers/IntersectionContainer';
import ColyseusClient from '../Network/ColyseusClient';
import envierments from '../../Environments/envierments';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventBus from '../Utils/EventBus';
import { LoadingCrircle } from './ChatBot';
import { CustomizableCylinderHollow } from './ZoomTo/CylinderHollow';
import Camera from '../Camera';
import XrControllers from './Controllers/XrControllers';
import { InteractionManager } from './Controllers/InteractionManager';
import { SimpleRotationControls } from './SimpleRotationControls';
import UserService from './UserService';

@autoInjectable()
export default class Model3dViewer extends VrObject3D {
    private model3dContainer: Object3D = new Object3D();
    private model3dPlatform: Object3D;
    private modelContainer: Object3D;
    private actualModel: Object3D;
    private interactionCube: Mesh;
    private loadingCircle: LoadingCrircle;
    private interactionManager: InteractionManager;
    private rotationControls: SimpleRotationControls;

    private rotationOn: boolean = false;
    private rotationSpeed: number = 0;
    private modelRotationDirection: 'x' | 'y' = 'y';
    private modelScale: number | null = null;
    private model3dContainerDimention: number = 2.5;

    private dracoLoader = new DRACOLoader();
    private gltfLoader = new GLTFLoader();

    public constructor(
        private intersectionContainer: IntersectionContainer,
        private colyseusClient: ColyseusClient,
        private eventBus: EventBus,
        private camera: Camera,
        private xrControllers: XrControllers,
        private user?: UserService,
    ) {
        super();

        this.setupLoaders();
        this.setupModel3dContainer();
        this.setupEventListeners();
        this.setupLoadingCircle();
        this.setupInteractionManager();
    }

    private setupLoaders() {
        this.dracoLoader.setDecoderPath('draco/');
        this.dracoLoader.setDecoderConfig({ type: 'js' });
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
    }

    private setupModel3dContainer() {
        this.model3dContainer.position.set(0, -0.85, 0);
        this.model3dContainer.scale.set(1, 1, 1);
        this.add(this.model3dContainer);
    }

    private setupEventListeners() {
        this.colyseusClient.addEventListener(
            'updateModel3dViewer',
            (data: any) => this.showModelFromUrl(data),
        );
        this.colyseusClient.addEventListener(
            'modelRotationUpdated',
            (event: any) => this.updateModelRotation(event.quaternion),
        );
    }

    private setupLoadingCircle() {
        this.loadingCircle = new LoadingCrircle();
        this.loadingCircle.visible = false;
        this.loadingCircle.position.set(0, 0, 0);
        this.add(this.loadingCircle);
    }

    private setupInteractionManager() {
        this.interactionManager = new InteractionManager(
            [
                this.xrControllers.controllerLeft,
                this.xrControllers.controllerRight,
            ],
            this.renderer.scene,
        );
    }

    public cleanup() {
        this.rotationControls?.dispose();
    }

    private updateModelRotation(rotation: Quaternion) {
        if (this.modelContainer) {
            this.modelContainer.quaternion.copy(rotation);
            this.modelContainer.updateMatrixWorld(true);
        }
    }

    public showModelFromUrl(data: any) {
        if (!data.changes || data.changes.length === 0) return;

        data.changes.forEach((change: any) => {
            switch (change.field) {
                case 'modelUrl':
                    this.changeModel(change.value);
                    break;
                case 'isModelRotating':
                    this.setModelRotating(change.value);
                    break;
                case 'modelRotationSpeed':
                    this.rotationSpeed = change.value;
                    break;
                case 'modelScale':
                    this.modelScale = change.value;
                    break;
                case 'modelRotationDirection':
                    this.modelRotationDirection =
                        change.value === 1 ? 'y' : 'x';
                    break;
                case 'modelVisibility':
                    this.model3dContainer.visible = change.value;
                    break;
            }
        });
    }

    private changeModel(url: string) {
        this.model3dContainer.clear();
        this.loadingCircle.visible = true;
        this.interactionManager.removeAllInteractiveObjects();

        if (url === 'none') {
            this.removeModelPlatform();
            this.loadingCircle.visible = false;
            this.eventBus.dispatchEvent({ type: 'model3dRemovedFromScene' });
        } else if (url.length > 0) {
            this.loadModel(url);
        }
    }

    private removeModelPlatform() {
        const platformIndex = this.children.findIndex(
            (child) => child.name === 'model-platform',
        );
        if (platformIndex !== -1) {
            this.children[platformIndex].removeFromParent();
        }
    }

    private loadModel(url: string) {
        const wholeUrl = envierments.baseURL + url.replace(/\/\/+/g, '/');

        this.gltfLoader.load(wholeUrl, (gltf) => {
            this.loadingCircle.visible = false;
            this.actualModel = gltf.scene;

            this.setupModelContainer();
            this.setupInteractionCube();
            if (this.user.is_school_teacher) {
                this.setupRotationControls();
            }
            this.setupModelPlatform();
            this.eventBus.dispatchEvent({ type: 'model3dAddedToScene' });
        });
    }

    private setupModelContainer() {
        this.modelContainer = new Object3D();
        this.model3dContainer.add(this.modelContainer);

        const boundingBox = new Box3().setFromObject(this.actualModel);
        const center = boundingBox.getCenter(new Vector3());
        const size = boundingBox.getSize(new Vector3());

        this.actualModel.position.sub(center);
        this.modelContainer.add(this.actualModel);
        this.modelContainer.rotation.set(0, 0, 0);
        this.modelContainer.updateMatrixWorld(true);

        const maxDimension = Math.max(size.x, size.y, size.z);
        const scale = this.model3dContainerDimention / maxDimension;
        this.actualModel.scale.set(scale, scale, scale);
    }

    private setupInteractionCube() {
        const cubeGeometry = new BoxGeometry(2, 2, 2);
        const cubeMaterial = new MeshBasicMaterial({
            visible: false,
            wireframe: false,
        });
        this.interactionCube = new Mesh(cubeGeometry, cubeMaterial);
        this.interactionCube.scale.copy(this.actualModel.scale);
        this.modelContainer.add(this.interactionCube);
    }

    private setupRotationControls() {
        this.rotationControls?.dispose();
        this.rotationControls = new SimpleRotationControls(
            this.renderer.webGLRenderer.domElement,
            this.camera.instance,
        );
        this.rotationControls.attach(this.modelContainer);
        this.rotationControls.onRotationChange = (rotation: Quaternion) => {
            this.colyseusClient.updateModelRotationQuaternion(rotation);
            this.camera.disableOrbitControls();
        };
        this.rotationControls.onRotationEnd = () => {
            this.camera.enableOrbitControls();
        };
    }

    private setupModelPlatform() {
        if (!this.model3dPlatform) {
            this.model3dPlatform = new CustomizableCylinderHollow(
                0.2,
                0.15,
                0.01,
                0,
                32,
            );
            this.model3dPlatform.rotation.x = Math.PI / 2;
            this.model3dPlatform.position.set(0, -1.2, 0);
            this.model3dPlatform.scale.set(5, 5, 5);
            this.model3dPlatform.name = 'model-platform';
        }

        if (!this.children.some((child) => child.name === 'model-platform')) {
            this.add(this.model3dPlatform);
        }
    }

    private setModelRotating(value: boolean) {
        this.rotationOn = value;
    }

    public update() {
        this.loadingCircle.visible && this.loadingCircle.update();
        this.interactionManager.update();

        if (
            this.resources.items.user.is_school_teacher &&
            this.modelContainer &&
            this.rotationOn &&
            this.rotationSpeed
        ) {
            const axis =
                this.modelRotationDirection === 'y'
                    ? new Vector3(0, 1, 0)
                    : new Vector3(1, 0, 0);
            const rotationQuaternion = new Quaternion().setFromAxisAngle(
                axis,
                this.rotationSpeed,
            );
            this.modelContainer.quaternion.multiply(rotationQuaternion);
            this.modelContainer.updateMatrixWorld(true);
            this.colyseusClient.updateModelRotationQuaternion(
                this.modelContainer.quaternion,
            );
        }

        if (this.modelScale && this.modelContainer) {
            this.modelContainer.scale.setScalar(this.modelScale);
        }
    }
}
