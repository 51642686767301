import PrivateRoomScene from '../PrivateRoom/PrivateRoomScene';
import GroupScene from '../Group/GroupScene';
// @ts-ignore
import Camera from '../../Camera';
import Time from '../../Utils/Time';

import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../../Components/Renderer';
import VrScene, { Rooms } from '../../Components/Three/VrScene';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import {
    Box3,
    Box3Helper,
    Color,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    Plane,
    PlaneGeometry,
    PlaneHelper,
    Quaternion,
    Vector3,
} from 'three';
import Resources from '../../Resources';
import IntersectionContainer from '../../Components/Controllers/IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import SchoolLobby from '../SchoolLobby/SchoolLobby';
import { NotificationsBoard } from '../../Components/Ui/NotificationsBoard';
import { Calendar } from '../../Components/Ui/Calendar';
import DoorView from '../../Components/Doors/Door.view';
import { AXIS, ZoomToView } from '../../Components/ZoomTo/ZoomTo.view';
import TextGenerator from '../../Components/Text/TextGenerator';
import PlayerInviter from '../../Components/PlayerInviter/PlayerInviter';

// test
import { Model3dList } from '../../Components/Ui/Model3dList';
import Model3dListLesson from '../../Components/Ui/Model3dListLesson';
import TranslationService from '../../Translations/TranslationService';

@singleton()
@autoInjectable()
export default class LobbyScene extends VrScene {
    public name = 'LobbyScene';

    public lobbyScene: Object3D;

    public constructor(
        public camera?: Camera,
        public time?: Time,
        public renderer?: Renderer,
        public httpClient?: AxiosHttpClient,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
    ) {
        super();
        this.lobbyScene = this.resources.items.lobbyScene.scene.clone();
        this.lobbyScene.position.y = -2.65;
        this.lobbyScene.getObjectByName('Cube003').visible = false;
        this.lobbyScene.getObjectByName('Text005').visible = false;
        this.add(this.lobbyScene);

        this.sceneNameForLabel = TranslationService.translate(
            'vr.main_lobby_label',
        );
        const roomLabel = new TextGenerator(
            TranslationService.translate('vr.main_lobby_label'),
        );
        roomLabel.position.set(-2.3, -2.5, 5);
        roomLabel.rotateY(MathUtils.degToRad(180));
        this.add(roomLabel);

        /**
         * this is join button event, now unnecessary because joining to room is automatic
         */
        // document
        //     .querySelector('.join-button')
        //     .addEventListener('click', async () => {
        //         document.querySelector('.join-button').classList.add('hidden');
        //         document
        //             .querySelector('.game-controlls')
        //             .classList.remove('hidden');
        //         this.start();
        //
        //         await this.joinRoom(
        //             this.httpClient.user.id + Rooms.Lobby,
        //             true,
        //         );
        //     });
    }

    public async start() {
        this.setTpRings();

        const groupDoors = new DoorView({
            group: true,
            doorSpacing: 2,
            localPlanes: [
                new Plane(new Vector3(1, 0, 0), 6),
                new Plane(new Vector3(-1, 0, 0), 1.9),
            ],
        });
        groupDoors.position.set(5, -1.3, 2.5);
        groupDoors.rotation.y = MathUtils.degToRad(-90);
        groupDoors.setLocalPlanesToGlobal(
            groupDoors.getWorldPosition(new Vector3()),
            groupDoors.getWorldQuaternion(new Quaternion()),
        );
        groupDoors.updateMatrixWorld();
        // group§.localPlanes.forEach((plane) => {
        //     const helper = new PlaneHelper(plane, 20, 0xffff00);
        //     this.add(helper);
        // });

        this.add(groupDoors);

        // -----------------
        // // TEST MODEL3D
        // const model3dList = new Model3dList();
        // this.add(model3dList);

        // const model3dListLesson = new Model3dListLesson();
        // this.add(model3dListLesson);

        // -----------------

        const notificationBoard = new NotificationsBoard();

        notificationBoard.scale.set(1.1, 1, 1.1);
        this.lobbyScene
            .getObjectByName('blank_not006')
            .getWorldPosition(notificationBoard.position);
        notificationBoard.rotateY(Math.PI / 2);
        this.add(notificationBoard);

        const zoomToNotification = new ZoomToView({
            target: notificationBoard.position,
            axis: AXIS.X,
            offset: 2.5,
        });

        zoomToNotification.rotateY(MathUtils.DEG2RAD * 90);

        const notificationZoomToPosition = notificationBoard.position.clone();
        notificationZoomToPosition.y += 1.22;
        notificationZoomToPosition.z -= 2.6;
        notificationZoomToPosition.x += 0.03;
        zoomToNotification.position.copy(notificationZoomToPosition);

        this.add(zoomToNotification);

        const calendarView = new Calendar();
        // calendarView.scale.set(0.8, 0.8, 0.8);
        calendarView.scale.set(0.86, 0.9, 0.9);
        this.lobbyScene
            .getObjectByName('Plane007')
            .getWorldPosition(calendarView.position);
        this.add(calendarView);

        const zoomToCalendar = new ZoomToView({
            target: calendarView.position,
            axis: AXIS.Z,
            offset: 2,
        });

        const calendarZoomToPosition = calendarView.position.clone();
        calendarZoomToPosition.y += 1;
        calendarZoomToPosition.x += 0.8;
        zoomToCalendar.position.copy(calendarZoomToPosition);

        this.add(zoomToCalendar);

        const floorGroup = new Object3D();

        floorGroup.name = 'floorGroup';

        const mesh = new Mesh(
            new PlaneGeometry(10, 10, 32),
            new MeshPhongMaterial({ color: new Color('pink') }),
        );

        mesh.name = 'floor';
        mesh.visible = false;
        mesh.position.y = -2.5;
        mesh.quaternion.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);

        floorGroup.add(mesh);

        this.addBoundingBox(mesh);

        this.add(floorGroup);

        // const groupDoor = this.setDoorTo(
        //     new GroupScene(),
        //     Rooms.GroupRoom,
        //     true,
        // );
        // groupDoor.rotateY(MathUtils.DEG2RAD * 180);
        //

        // groupDoor.position.copy(
        //     this.lobbyScene
        //         .getObjectByName('Cube006')
        //         .getWorldPosition(new Vector3()),
        // );
        // this.add(groupDoor);

        const user = this.resources.items.user;

        const privateRoomDoor = this.setDoorTo(
            new PrivateRoomScene(),
            user.id,
            true,
        );
        this.lobbyScene
            .getObjectByName('Cube019')
            .getWorldPosition(privateRoomDoor.position);
        this.add(privateRoomDoor);

        const schoolLobby = this.setDoorTo(
            new SchoolLobby(),
            this.resources.items.user.school_id,
            true,
        );
        schoolLobby.rotateY(MathUtils.DEG2RAD * 180);
        this.lobbyScene
            .getObjectByName('Cube004_1')
            .getWorldPosition(schoolLobby.position);
        this.add(schoolLobby);
    }
}
