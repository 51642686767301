import React, { useEffect, useState } from 'react';
import AxiosHttpClient from '../../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../../../Environments/envierments';
import ColyseusClient from '../../../Network/ColyseusClient';
import { Model3dPanelDetails } from './3d-panel-details';
import defaultCourseIcon from '@assets/icons/defaultCourseIcon.svg';
import '../main.css';
import PanelHeader from './3d-panel-header';
import { ClipLoader } from 'react-spinners';
import LoadedModels from './loaded-models';
import TranslationService from '../../../Translations/TranslationService';

export function Model3dPanel({ handleClose, handleUnclick, style }) {
    const httpClient = container.resolve(AxiosHttpClient);

    const [allCollections, setAllColletions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [showPanelDetails, setShowPanelDetails] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [showMask, setShowMask] = useState(true);

    useEffect(() => {
        const fetchCollection = async () => {
            let collection = await httpClient.getCourseCollection();

            let _course = [];

            if (collection.data.length > 0) {
                setShowMask(false);
                setAllColletions(collection.data);

                // kursy
                for (let cCourses of collection.data) {
                    for (let course of cCourses.collections) {
                        _course.push(course);
                    }
                }
                setCourses(_course);
            }
        };

        fetchCollection().catch(console.error);
    }, []);

    const handleShowPanelDetails = async (e, id) => {
        setSelectedId(id);
        setShowPanelDetails(true);
    };

    const closeAllHandler = () => {
        setShowPanelDetails(false);
        handleClose(), handleUnclick(1);
    };

    const PanelViewerDetails = () => {
        return (
            <Model3dPanelDetails
                courseId={selectedId}
                onChange={() => {
                    setShowPanelDetails(!showPanelDetails);
                }}
                close={closeAllHandler}
            />
        );
    };

    const Loader = () => {
        if (showMask) {
            return (
                <div className="spinner-clip">
                    <ClipLoader
                        color={'#FFF'}
                        loading={showMask}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            );
        }
    };

    const PanelViewer = () => {
        return (
            <div className="model3d-panel-list">
                <PanelHeader
                    backButton={false}
                    closeButtonHandler={() => {
                        handleClose();
                        handleUnclick();
                    }}
                    backButtonHandler={() => {}}
                    title={TranslationService.translate(
                        'vr.3d_models_collection',
                    )}
                    description={TranslationService.translate(
                        'vr.3d_panel_viewer_description',
                    )}
                />
                <LoadedModels />

                <Loader />

                <div className="collections-model-viewer">
                    {courses.map((course, key) => (
                        <div
                            className="model3d-course"
                            key={key}
                            onClick={(e) =>
                                handleShowPanelDetails(e, course.id)
                            }
                        >
                            <img
                                className="model3d-course-icon"
                                src={
                                    course.icon_url ===
                                    '/media/img/default_course_icon.svg'
                                        ? defaultCourseIcon
                                        : envierments.baseURL +
                                          `/file/serve/${course.icon_url}`
                                }
                            />
                            <div
                                className="model3d-course-title"
                                key={course.id}
                            >
                                <p>{course.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const View = () => {
        if (showPanelDetails) {
            return <PanelViewerDetails />;
        } else {
            return <PanelViewer />;
        }
    };

    return (
        <div className="popup-wrapper-model-viewer" style={style}>
            <div className="popup-content-model-viewer">
                <View />
            </div>
        </div>
    );
}
