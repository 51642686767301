import VrObject3D from '../Three/VrObject3D';
import { autoInjectable } from 'tsyringe';
import Resources from '../../Resources';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import {
    Color,
    EdgesGeometry,
    LineBasicMaterial,
    LineSegments,
    Material,
    Mesh,
    MeshStandardMaterial,
} from 'three';

@autoInjectable()
export default class TextGenerator extends VrObject3D {
    public textGeometry: TextGeometry;
    public material: Material;
    public depth: number = 0.07;
    public size: number = 0.5;
    public mesh: Mesh;
    public outlineMesh: LineSegments;
    public outlineGeometry: EdgesGeometry;
    public constructor(text: string, public resources?: Resources) {
        super();

        this.textGeometry = new TextGeometry(text, {
            font: this.resources.items.helvetiker,
            size: this.size,
            height: this.depth,
            curveSegments: 12,
            bevelEnabled: false,
        });

        this.material = new MeshStandardMaterial({
            color: new Color('#FFFFFF'),
            emissive: new Color('#043087'),
            transparent: true,
            opacity: 0.75,
        });

        this.outlineGeometry = new EdgesGeometry(this.textGeometry);
        this.outlineMesh = new LineSegments(
            this.outlineGeometry,
            new LineBasicMaterial({
                color: new Color('#043087'),
                transparent: true,
                opacity: 0.5,
            }),
        );
        this.add(this.outlineMesh);

        this.scale.set(0.8, 0.8, 0.1);

        this.mesh = new Mesh(this.textGeometry, this.material);

        this.add(this.mesh);
    }

    public setAnchor(align: 'RIGHT' | 'LEFT') {
        if (align === 'RIGHT') {
            this.textGeometry.computeBoundingBox();
            const textWidth =
                this.textGeometry.boundingBox.max.x -
                this.textGeometry.boundingBox.min.x;
            this.textGeometry.translate(-textWidth, 0, 0);

            this.outlineGeometry.translate(-textWidth, 0, 0);
        }
    }
}
